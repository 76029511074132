import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { store } from '../store';
import { setActiveBets } from '../store/betList/slices';
import { setUrlParams } from '../store/user/slices';
const { REACT_APP_FINANCE_SERVICE } = process.env;

const TIMEOUT = 20 * 60 * 1000;

const BASE_URL = REACT_APP_FINANCE_SERVICE
  ? `${REACT_APP_FINANCE_SERVICE}/api`
  : 'https://dev.tglabs-trading.limeup.online/api';

export const requester = axios.create({
  baseURL: BASE_URL,
});

requester.defaults.timeout = TIMEOUT;

export const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const appStore = store.getState();
    if (appStore.user.urlParams) {
      config.params = { ...config.params, ...appStore.user.urlParams };
    }
    return config;
  };

  const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  const onResponseError = (err: AxiosError) => {
    const status = err.response?.status ?? 0;

    if (status === 403 || status === 401) {
      console.log('status', status);
      store.dispatch(setUrlParams(null));
      store.dispatch(setActiveBets([]));
    }

    return Promise.reject(err);
  };

  requester.interceptors.request.use(onRequestSuccess);
  requester.interceptors.response.use(onResponseSuccess, onResponseError);
};
