import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { setupAxiosInterceptors } from '../api/axiosInstance';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { useAppDispatch } from '../store';
import { setPlayerId, setUrlParams } from '../store/user/slices';

const ApiService = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { urlParams } = useTypedSelector((state) => state.user);

  // const launchGame = useCallback(
  //   async (player: string | null) => {
  //     try {
  //       const response = await axios({
  //         method: 'post',
  //         url: `${API.START_GAME}/${player}/external_games/launch`,
  //         data: {
  //           GameProvider: 'CasinoGames::Limeup',
  //           CasinoProviderId: 178,
  //           GameId: 'test-limeup',
  //           Source: 1,
  //           Locale: 'en_GB',
  //           Demo: false,
  //           AdditionalData: null,
  //         },
  //       });
  //
  //       if (response.data?.Parameters) {
  //         dispatch(setPlayerId(Number(player)));
  //         dispatch(setUrlParams(response.data.Parameters));
  //       }
  //     } catch (e) {
  //       console.log('Error get token');
  //       dispatch(setUrlParams(null));
  //     }
  //   },
  //   [dispatch],
  // );

  useEffect(() => {
    const params = searchParams.entries();
    const paramsObj: any = {};
    // @ts-ignore
    for (const [key, value] of params) {
      paramsObj[key] = value;
    }
    if (Object.keys(paramsObj).length > 3) {
      if (!paramsObj.providerId) {
        paramsObj.providerId = 178;
      }
      dispatch(setUrlParams(paramsObj));
      dispatch(setPlayerId(paramsObj.accountId));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (urlParams) {
      setupAxiosInterceptors();
    }
  }, [urlParams]);

  return null;
};

export default ApiService;
