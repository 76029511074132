import React from 'react';
import classNames from 'classnames';

import { SearchIcon } from '../../assets/icons';
import BTCIcon from '../../assets/images/btc.png';
import CDNIcon from '../../assets/images/CDN.png';
import ETHIcon from '../../assets/images/eth.png';
import CoinMenuItem from '../../components/CoinMenuItem/CoinMenuItem';

import styles from './CryptoList.module.scss';

export default function CryptoList() {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        <div className={styles.leftBlock}>
          <span className={styles.headerText}>Crypto Futures</span>
          <div className={styles.subHeader}>
            <span>
              <span className={styles.labelText}>Global 24h Volume: </span>
              <span className={styles.valueText}>$1.1B </span>
            </span>
            <span>
              <span className={styles.labelText}>Increased by: </span>
              <span className={styles.valueText}>0.43% </span>
              <span className={classNames(styles.labelText, styles.period)}>(1 day)</span>
            </span>
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.input}>
            <SearchIcon />
            <input type='text' placeholder='Search for coins...' />
          </div>
        </div>
      </div>
      <div className={styles.itemsContainer}>
        {Array.isArray(coins) &&
          coins.map((item) => (
            <CoinMenuItem
              max_multiplier={item.max_multiplier}
              name={item.name}
              title={item.title}
              icon_id={item.icon_id}
              icon={item.icon}
              key={item.name}
            />
          ))}
      </div>
    </div>
  );
}

const coins = [
  {
    icon_id: '1',
    icon: BTCIcon,
    max_multiplier: 1000,
    name: 'BTC',
    title: 'Bitcoin',
  },
  {
    icon_id: '2',
    icon: ETHIcon,
    max_multiplier: 1000,
    name: 'ETH',
    title: 'Ethereum',
  },
  {
    icon_id: '3',
    icon: CDNIcon,
    max_multiplier: 1000,
    name: 'CDN',
    title: 'Canada eCoin',
  },
];
